import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Search from '../Search';
import SubMenu from './subMenu';
import ImageComponent from '../../utils/imageComponent';
import LinkComponent from '../../utils/linkComponent';
import React, { useState, useContext } from 'react';
import CustomContext from '../../../custom-context';
import { NavDropdown } from 'react-bootstrap';

const searchIndices = [
	{ name: `Pages`, title: `Pages` },
	{ name: `Resources`, title: `Resources` },
	{ name: `Events`, title: `Events` },
];
const StyledLink = styled((props) => <LinkComponent {...props} />)`
	font-size: var(--font-size-base);
	margin: 0em 0.7em;
	/* color: var(--secondary_2) !important; */
	padding: 10px 0px;
	color: var(--secondary_2);
	text-decoration: none;
	white-space: nowrap;
	align-self: center;
	// mobile settings
	@media (max-width: 1199.5px) {
		font-weight: 600;
		margin-left: 0em;
		align-self: start;
		.mainMenu {
			top: 0px !important;
		}
	}
`;

const NavStyled = styled.div`
	position: sticky;
	top: -1px;
	z-index: 1000;

	.navbar-light .navbar-nav .nav-link {
		color: var(--secondary_2) !important;
		font-size: var(--font-size-base);
	}

	.mainMenu {
		background: var(--secondary_7);
		position: sticky;
		top: 0;
	}

	.navbar-light .navbar-brand {
		color: var(--secondary_6);
	}

	.navbar-light .navbar-toggler {
		border-color: var(--secondary_7) !important;
		color: var(--secondary_2) !important;
	}
	.topmenu-dropdown {
		display: inline-grid;
	}
	.loginButton {
		background-color: var(--primary);
		color: var(--secondary_6);
		width: 7em;
		text-align: center;
		font-size: 16px;
		margin-left: 1em;
		padding: 0.3em;
		cursor: pointer;
	}
	.login .nav-link {
		font-size: var(--font-size-md);
		margin: 0em 0.7em;
		/* color: var(--secondary_2) !important; */
		padding: 12px 0px;
		color: var(--primary);
		text-decoration: none;
		white-space: nowrap;
		align-self: center;
	}
	.login-menu-button {
		//padding-top: 5px;
	}
	.hamburger-login-wrapper {
		display: flex;
	}
	.dropdown-menu {
		background-color: var(--secondary_7);
		color: var(--secondary_2);
	}
	.nav-item.dropdown:hover .dropdown-menu {
		display: block;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		color: var(--secondary_4);
		background-color: var(--secondary_7);
	}
	.hamburger-icon {
		padding-left: 0.5em;
	}
	.logout {
		height: 40px;
	}
	// .navbar-toggler-icon {
	// 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	// }

	// tablets settings
	@media (max-width: 1199.5px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			font-weight: 600;
			font-size: var(--font-size-base) !important;
			width: 100%;
		}
		.dropdown-menu {
			padding: 10px 0px;
		}
		.nav-link {
			font-size: var(--font-size-base) !important;
			margin: 0 !important;
			padding: 0 !important;
		}
		.logoutButton {
			font-size: var(--font-size-base) !important;
			margin: 0 0;
			text-align: left;
		}
		.sub-menu:hover .dropdown-submenu {
			display: contents !important;
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			font-weight: 600;
			font-size: var(--font-size-base) !important;
			width: 100%;
		}
		.dropdown-menu {
			padding: 10px 0px;
		}
		.nav-link {
			font-size: var(--font-size-base) !important;
			margin: 0 !important;
			padding: 0 !important;
		}
		.menuButton {
			padding: 0 0;
		}
	}
`;
const Header = () => {
	const [expanded, setExpanded] = useState(false);
	const { user, setUser, setToken } = useContext(CustomContext);
	const menuDataAndLogoData = useStaticQuery(graphql`
		query menuQueryAndStarpiAssetsLogo {
			strapiAsset {
				logo {
					alternativeText
					caption
					url
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
			allStrapiNavigationPluginMainNavigation(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
						sub_menu_icon
						items {
							path
							title
							type
						}
					}
					order
					title
					type
					path
				}
			}
		}
	`);
	return (
		<NavStyled>
			<Navbar
				variant='light'
				fixed='top'
				expand='xl'
				className='mainMenu'
				collapseOnSelect
				expanded={expanded}
				onToggle={() => setExpanded((expanded) => !expanded)}
			>
				<Container>
					<Navbar.Brand
						onClick={() => {
							setExpanded(false);
							navigate('/');
						}}
						style={{ cursor: 'pointer', width: '200px' }}
					>
						<ImageComponent
							imageItem={menuDataAndLogoData.strapiAsset.logo}
							alt='Logo'
						></ImageComponent>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<Search setExpanded={setExpanded} indices={searchIndices} />
							{/* Adding a multilevel navbar with dropdowns */}
							{menuDataAndLogoData.allStrapiNavigationPluginMainNavigation.nodes.map(
								(menuItem) =>
									menuItem.items?.length > 0 ? (
										<SubMenu
											setExpanded={setExpanded}
											menuItem={menuItem}
										></SubMenu>
									) : (
										<StyledLink
											onClick={() => setExpanded(false)}
											className='navItem'
											to={menuItem.path}
											key={menuItem.title}
										>
											{menuItem.title}
										</StyledLink>
									)
							)}
							{user !== null && user !== undefined ? (
								<StyledLink
									to='/dashboard'
									className='navItem'
									onClick={() => setExpanded(false)}
								>
									{' '}
									Dashboard
								</StyledLink>
							) : null}
							{/* {user !== null && user !== undefined ? (
								<div
									className='logoutButton'
									onClick={() => {
										setUser(null);
										setToken(null);
									}}
									onKeyDown={() => {
										setUser(null);
										setToken(null);
									}}
									role='button'
									tabIndex='0'
								>
									{' '}
									Logout
								</div>
							) : (
								<NavDropdown
									align='end'
									id='nav-dropdown-basic'
									title='Login'
									className='navItem login'
									renderMenuOnMount={true}
									// data-bs-theme='dark'
									// className='loginButton'
								>
									<NavDropdown.Item
										href={`${process.env.GATSBY_AAF_LOGIN_URL}`}
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via AAF
									</NavDropdown.Item>
									<NavDropdown.Item href='/login-help/'>
										Login help
									</NavDropdown.Item>
								</NavDropdown>
							)} */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};
export default Header;
