import React from 'react';
import Header from '../Header/header';
// import TopMenu from '../Header/topMenu';
import Copyright from '../Footer/copyright';
import FooterLinks from '../Footer/footerLinks';
import Acknowledgement from '../Footer/acknowledgement';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource-variable/raleway';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/heebo';
import './layout.css';
import styled, { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const LayoutStyled = styled.div`
	--neutral_1: ${(props) => props.theme.neutral_1};
	--neutral_2: ${(props) => props.theme.neutral_2};
	--primary: ${(props) => props.theme.primary};
	--secondary_1: ${(props) => props.theme.secondary_1};
	--secondary_2: ${(props) => props.theme.secondary_2};
	--secondary_3: ${(props) => props.theme.secondary_3};
	--secondary_4: ${(props) => props.theme.secondary_4};
	--secondary_5: ${(props) => props.theme.secondary_5};
	--secondary_6: ${(props) => props.theme.secondary_6};
	--secondary_7: ${(props) => props.theme.secondary_7};
	--secondary_8: ${(props) => props.theme.secondary_8};
	--secondary_9: ${(props) => props.theme.secondary_9};
	--dark_overlay: ${(props) => props.theme.dark_overlay};
	--light_overlay: ${(props) => props.theme.light_overlay};
	--font_color_h1: ${(props) => `var(--${props.theme.h1})`};
	--font_color_h2: ${(props) => `var(--${props.theme.h2})`};
	--font_color_h3: ${(props) => `var(--${props.theme.h3})`};
	--font_color_h4: ${(props) => `var(--${props.theme.h4})`};
	--font_color_h5: ${(props) => `var(--${props.theme.h5})`};
	--font_color_h6: ${(props) => `var(--${props.theme.h6})`};
	--hero_title_font_family: ${(props) => props.theme.hero_title_font_string};
	--primary_font_family: ${(props) => props.theme.font_string};
	--link_color: ${(props) => props.theme.link_color};
`;

const Layout = ({ children }) => {
	var themeData = useStaticQuery(graphql`
		query starpiAssets {
			strapiAsset {
				brand_colours {
					neutral_1
					neutral_2
					secondary_1
					primary
					secondary_2
					secondary_3
					secondary_4
					secondary_5
					secondary_6
					secondary_7
					secondary_8
					secondary_9
					dark_overlay
					light_overlay
					link_color
				}
				font_color {
					h1
					h2
					h3
					h4
					h5
					h6
				}
				font_family {
					alternative_font_family
					hero_title_font_family
					primary_font_family
				}
			}
		}
	`);
	const brand_colours = themeData.strapiAsset.brand_colours;
	const font_color = themeData.strapiAsset.font_color;
	var font_family = themeData.strapiAsset.font_family;

	// a switch case to check which font family is present and then create a variable string to assign it to font-family css class
	function checkFontFamily(fontFamily) {
		// console.log('font_family: ', font_family);
		var font_family_string = '';
		switch (fontFamily) {
			case 'raleway':
				font_family_string = 'Raleway Variable,';
				break;
			case 'montserrat':
				font_family_string = 'Montserrat Variable,';
				break;
			case 'heebo':
				font_family_string = 'Heebo Variable,';
				break;
			case 'boing':
				font_family_string = 'Boing,';
				break;
			default:
				font_family_string = '';
		}
		return font_family_string;
	}

	// get the primary fontFamily and alternative fontFamily and create a string to assign it to font-family css String
	var fontString = checkFontFamily(font_family.primary_font_family);
	var altFontString =
		font_family.alternative_font_family !== 'arial'
			? checkFontFamily(font_family.alternative_font_family)
			: '';
	altFontString =
		altFontString + 'Arial, "Helvetica Neue", Helvetica, sans-serif';
	fontString = fontString + altFontString;
	// get the hero title fontFamily and alternative fontFamily and create a string to assign it to font-family css String
	var heroTitleFontFamilyString = checkFontFamily(
		font_family.hero_title_font_family
	);
	heroTitleFontFamilyString = heroTitleFontFamilyString + altFontString;
	// Define what props.theme will look like
	const theme = {
		neutral_1: brand_colours.neutral_1,
		neutral_2: brand_colours.neutral_2,
		primary: brand_colours.primary,
		secondary_1: brand_colours.secondary_1,
		secondary_2: brand_colours.secondary_2,
		secondary_3: brand_colours.secondary_3,
		secondary_4: brand_colours.secondary_4,
		secondary_5: brand_colours.secondary_5,
		secondary_6: brand_colours.secondary_6,
		secondary_7: brand_colours.secondary_7,
		secondary_8: brand_colours.secondary_8,
		secondary_9: brand_colours.secondary_9,
		light_overlay: brand_colours.light_overlay,
		dark_overlay: brand_colours.dark_overlay,
		link_color: brand_colours.link_color,
		font_string: fontString,
		hero_title_font_string: heroTitleFontFamilyString,
		h1: font_color.h1,
		h2: font_color.h2,
		h3: font_color.h3,
		h4: font_color.h4,
		h5: font_color.h5,
		h6: font_color.h6,
	};
	return (
		<ThemeProvider theme={theme}>
			<LayoutStyled id='layoutStyled'>
				{/* <TopMenu /> */}
				<Header />
				<div id='bodyChildren'>{children}</div>
				<Acknowledgement />
				<FooterLinks props={children.props?.data?.strapiPage}></FooterLinks>
				<Copyright />
			</LayoutStyled>
		</ThemeProvider>
	);
};

export default Layout;
