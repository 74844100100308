import '@fontsource/raleway';
import '@fontsource/heebo';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const { CustomProvider } = require('./custom-context');
const React = require('react');

export function wrapPageElement({ element, props }) {
	return <CustomProvider {...props}>{element}</CustomProvider>;
}

export function onRouteUpdate({ location, prevLocation }) {
	if (location?.pathname !== prevLocation?.pathname) {
		// ignore this function if the path has the scroll hash identifier
		// check for a location.hash value and whether it is starting with #
		// if it does, return from the function
		if (location?.hash?.startsWith('#')) {
			return;
		}
		window?.scrollTo(0, 0);
		//scroll to the top banner
		document
			?.getElementById('bodyChildren')
			?.scrollIntoView({ behavior: 'smooth' });
		document
			?.getElementById('gatsby-focus-wrapper')
			?.scrollIntoView({ behavior: 'smooth' });
	}
}
