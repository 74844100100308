exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-hubspot-js": () => import("./../../../src/pages/hubspot.js" /* webpackChunkName: "component---src-pages-hubspot-js" */),
  "component---src-pages-login-help-js": () => import("./../../../src/pages/login-help.js" /* webpackChunkName: "component---src-pages-login-help-js" */),
  "component---src-pages-member-institutes-js": () => import("./../../../src/pages/member-institutes.js" /* webpackChunkName: "component---src-pages-member-institutes-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources/[...].js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-detail-js": () => import("./../../../src/templates/peopleDetail.js" /* webpackChunkName: "component---src-templates-people-detail-js" */),
  "component---src-templates-resource-detail-js": () => import("./../../../src/templates/resourceDetail.js" /* webpackChunkName: "component---src-templates-resource-detail-js" */)
}

